MicroModal.init({
    disableScroll: true,
    awaitCloseAnimation: true,
    onShow: function(e){
        let modalContent = e.querySelector('.ae_modal__content')
        if (!e.classList.contains('tv-popis')) {
            let img = e.querySelector('.ae_modal__header img')
            let imgHeight = parseFloat(getComputedStyle(img, null).height.replace("px", ""))            
            //console.log(imgHeight)
            modalContent.style.height = "calc(80vh - "+(imgHeight + 30)+"px)"
        }        
        modalContent.scrollTop = 0;
    }
});

/**
 * 
 * Check if Order Form exists
 * 
 */


const observer = new MutationObserver(function(mutations_list) {
    mutations_list.forEach(function(mutation) {
        mutation.addedNodes.forEach(function(added_node) {
            if(added_node.querySelector('input[name="packages"]')) {
                console.log("added nodes");
                //orderForm = document.querySelector('#order-form');
                //console.log("new form: ", orderForm)
                setTimeout(() => {                    
                    runOrderFormScripts();
                }, 500);
                observer.disconnect();
            }
        });
    });
});

if (document.querySelector('#order-form')) {

observer.observe(document.querySelector("#order-form"), { subtree: true, childList: true });

let selectCustom = document.querySelectorAll('.ae_custom-dropdown > span');
//let selectCustomRadio = document.querySelectorAll('.ae_custom-dropdown input');

/**
 * 
 * Custom select field 
 * - open/close control
 * 
 */

selectCustom.forEach(function(i){
    i.addEventListener('click', function(e){
        e.stopPropagation();
        // close other selects
        selectCustom.forEach(function(ii){
            if (i != ii) {
                ii.parentNode.classList.remove('open');
            }
        });
        // toggle current
        i.parentNode.classList.toggle('open')        
    })
})
// close select if click outside
document.body.addEventListener('click', function(e){
    if ( e.target != selectCustom ) {
        selectCustom.forEach(function(i){
            if ( i.parentNode.classList.contains('open') ) {
                i.parentNode.classList.remove('open')
            }
        })
    }
});

/**
 * 
 * Upload fields
 * - show name of uploaded file 
 * - else, show preview of uploaded image
 * 
 */
document.querySelectorAll('input[type="file"]').forEach(function(i){
    i.addEventListener('change', function(e){
        const file = e.target.files[0]
        //console.log(file)
        if (file) {
            if (e.target.nextElementSibling.classList.contains('file-custom')) {
                e.target.nextElementSibling.innerHTML = file.name
            } else {
                e.target.nextElementSibling.src = URL.createObjectURL(file)
            }
        }
        
    })
})

/**
 * 
 * Destroy form elements with .poslovni-only class if viewing from .privatni
 * 
 */
if (document.body.classList.contains('privatni')) {
    document.querySelectorAll('#order-form .poslovni-only').forEach(function(i){
        i.remove()
    })
}

/**
 * 
 * Max Length on inputs limit
 * 
 */
document.querySelectorAll('input[maxlength]').forEach(function(i){
    i.addEventListener('input', function(){
        if (i.value.length > i.maxLength) {
            i.value = i.value.slice(0,this.maxLength)
        }
    })
})

}


function runOrderFormScripts() {


    let orderForm = document.querySelector('#order-form');

    let orderFormStep1 = document.querySelector('#order-form .step-1');
    let orderFormStep2 = document.querySelector('#order-form .step-2');
    let orderFormStep3 = document.querySelector('#order-form .step-3');
    let orderFormStepTotal = document.querySelector('#order-form .step-total');
    
    let btnOpenStep3 = document.querySelector('#order-form a.step-3-open');
    
    let totalPriceTxt = document.querySelector('#order-form p.total-text span');
      
    let btnResetFrom = document.querySelector('#order-form .form-reset')





/**
 * 
 * Order Form
 * - STEP 1 close on package select
 * - STEP 2 open
 * 
 */
 orderForm.packages.forEach(function(i){
    i.addEventListener('change', function(e) {
        //console.log(i.nextElementSibling.innerHTML)
        orderFormStep1.classList.add('close')
        orderFormStep2.classList.add('open')
        orderFormStepTotal.classList.add('open')
        orderForm.querySelector('.package-title').innerHTML = i.nextElementSibling.innerHTML

        setTimeout(() => {

            document.querySelector('.ae_section-form-package').scrollIntoView({ 
                behavior: 'smooth' 
            });

            //orderForm = document.querySelector('#order-form');
            //selectCustom = document.querySelectorAll('.ae_custom-dropdown > span');
            let selectCustomRadio = document.querySelectorAll('.ae_custom-dropdown input');

            selectCustomRadio.forEach(function(i){
                i.addEventListener('change', function(){
                    customSelectChooseOption(i)
                    //console.log("custom radio changed")
                })
            })

        }, 300);

    })
})

/**
 * 
 * Order Form
 * - STEP 3 open on button click
 * 
 */
btnOpenStep3.addEventListener('click', function(e) {
    orderFormStep3.classList.add('open')
    this.style.display = "none"
    orderForm.querySelector('.step-3').scrollIntoView({ 
        behavior: 'smooth' 
    });
})

/**
 * 
 * Order Form 
 * STEP 2:
 *  - look for global changes on form inputs
 *  - if change detected, get all valid inputs with prices and re-calc. total
 *  - show STEP 3 button if one of the required fields is selected
 * STEP 3:
 *  - call formValidation() that engages with step 3 only
 * 
 */
orderForm.addEventListener('change', function(e) {
    //console.log('ping');    
    let priceArray = [];
    let priceSum = 0;
    for (let i = 0; i < orderForm.elements.length; i++) {
        if (orderForm.elements[i].checked && orderForm.elements[i].dataset.price) {
            priceArray.push(orderForm.elements[i].dataset.price)
            //console.log(priceArray)
        }
    }
    for (let i = 0; i < priceArray.length; i++) {
        priceSum += parseFloat(priceArray[i]);
    }
    orderForm.cartTotal.value = priceSum.toFixed(2)
    totalPriceTxt.innerHTML = priceSum.toFixed(2).replace('.', ',')

    //Check if selectSpeed input has been chosen, then show button next
    document.querySelectorAll('#order-form input[name="selectSpeed"]').forEach(function(i){
        if(i.checked && !orderFormStep3.classList.contains('open')) {
            btnOpenStep3.style.display = 'inline-block';
        }
    })

    formValidation(e)

});


/**
 * 
 * Order Form
 * - rest all form data and return to STEP 1
 * 
 */
btnResetFrom.addEventListener('click', function(){
    //Reset form inputs
    orderForm.reset()    
    //Reset custom selects
    document.querySelectorAll('.ae_custom-dropdown > span').forEach(function(i){
        i.innerHTML = "Odaberi"
    })
    //Reset price and next button display
    totalPriceTxt.innerHTML = "0"
    btnOpenStep3.style.display = 'none'
    //Close / open steps and reset key value
    orderFormStep2.classList.remove('open')
    orderFormStep3.classList.remove('open')
    orderFormStep1.classList.remove('close')
    orderFormStepTotal.classList.remove('open')
    orderForm.cartTotal.value = 0;
    //Scroll to top of form
    orderForm.scrollIntoView({ 
        behavior: 'smooth' 
    });
})


// Kristijan
// Check GDPR
let gdprBtn = document.querySelector('#gdprMain');

gdprBtn.addEventListener('change', function(e){
    e.preventDefault();

    if(hasClass(this, 'success') == false){
        document.querySelector('#order-form #btnSubmit').style.display = 'inline-block';
    } else {
        document.querySelector('#order-form #btnSubmit').style.display = 'none';
    }
});

function hasClass(element, cls) {
    return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1;
}
// Kristijan



// Order from function END
}


if (document.querySelector("#availability-form")) {

/**
 * 
 * Avilability form
 * RUNE fields show/hide & required based on region select
 * 
 */
 let selectRegija = document.querySelector('#availability-form-regija');
 let fieldNaselje = document.querySelector('#availability-form-naselje');
 let fieldBrStana = document.querySelector('#availability-form-brstana');
 let fieldPostBr = document.querySelector('#availability-form-postanski-broj')
 
 fieldNaselje.parentElement.setAttribute("style", "display:none")
 fieldBrStana.parentElement.setAttribute("style", "display:none")
 fieldPostBr.parentElement.setAttribute("style", "display:none")
 
 selectRegija.addEventListener("change", function(){
     if (this.value > 0){
         fieldNaselje.setAttribute("required", "required")
         fieldBrStana.setAttribute("required", "required")
         fieldPostBr.setAttribute("required", "required")
         fieldPostBr.parentElement.setAttribute("style", "display:block")
         fieldNaselje.parentElement.setAttribute("style", "display:block")
         fieldBrStana.parentElement.setAttribute("style", "display:block")
     }
     else {
         fieldNaselje.removeAttribute("required", "required")
         fieldBrStana.removeAttribute("required", "required")
         fieldPostBr.removeAttribute("required", "required")
         fieldPostBr.parentElement.setAttribute("style", "display:none")
         fieldNaselje.parentElement.setAttribute("style", "display:none")
         fieldBrStana.parentElement.setAttribute("style", "display:none")
     }
 });

}




/**
 * 
 * Custom select field
 * - show selected option text
 * 
 */

let customSelectChooseOption = function (i) {
    // Save option description
    let description = i.nextElementSibling.innerHTML;
    // Remove parent toggle class
    i.closest('.ae_custom-dropdown').classList.remove('open');
    // Inject description
    i.closest('.ae_custom-dropdown').firstElementChild.innerHTML = description;
}

/**
 * 
 * Order Form
 * - form validation function (colors)
 * - show final submit button if all required inputs in STEP 3 are filled & valid
 * 
 */
 //let allAreFilled = 0;
 function formValidation(e) {
    
    if (document.querySelector('#order-form .step-3.open')) {

        let requiredStep3 = document.querySelectorAll("#order-form .step-3.open [required]");
        let successStep3 = document.querySelectorAll("#order-form .step-3.open input.success");
        //let allAreFilled = true;        
        let oibValid;
        //console.log(requiredStep3.length, successStep3.length)
        

        if ( e.target.checkValidity() && ( e.target != document.querySelectorAll("#order-form input#oib")[0] ) ) {
            e.target.classList.add('success')
            e.target.classList.remove('danger')
        } else if ( !e.target.checkValidity() && (e.target != document.querySelectorAll("#order-form input#oib")[0] ) ) {
            e.target.classList.add('danger')
            e.target.classList.remove('success')
        } else {
            oibValid = oibCheck(e.target.value)
            if (oibValid) {
                e.target.classList.add('success')
                e.target.classList.remove('danger')
            } else {
                e.target.classList.add('danger')
                e.target.classList.remove('success')
            }
        }

        // if ( requiredStep3.length == successStep3.length ) {
        //     //console.log("valid! ", allAreFilled,oibValid)
        //     document.querySelector('#order-form #btnSubmit').style.display = 'inline-block'
        // } else {
        //     document.querySelector('#order-form #btnSubmit').style.display = 'none'
        // }
    }    
}


/**
 * 
 * OIB VALIDATOR
 * SRC: https://github.com/otisid/oib-js-validator/blob/master/oib-validator.js
 * 
 */
 function oibCheck(oibCode) {
	var checkDigit, num;

	var code = oibCode.toString();

	if (code.length === 13 && code.substr(0, 2).toUpperCase() === 'HR') {
		code = code.substr(2, 11);
	} else if (code.length !== 11) {
		return false;
	}

	var numCheck = parseInt(code, 10);
	if (isNaN(numCheck)) {
		return false;
	}

	num = 10;
	for (var i = 0; i < 10; i++) {
		num = num + parseInt(code.substr(i, 1), 10);
		num = num % 10;
		if (num === 0) {
			num = 10;
		}
		num *= 2;
		num = num % 11;
	}

	checkDigit = 11 - num;
	if (checkDigit === 10) {
		checkDigit = 0;
	}
	
	return parseInt(code.substr(10, 1), 10) === checkDigit;
}





/**
 * 
 * FAQ Accordion
 * 
 */

let acc = document.querySelectorAll('.ae_accordion-btn')

acc.forEach(function(i){
    i.addEventListener('click', function(){
        i.classList.toggle("active");

        let panel = i.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
    })
})
